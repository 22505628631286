<template>
  <div>
    <button class="btn btn-primary float-right" @click="fetchItems"><b-icon icon="b-arrow-clockwise" /> Aktualisieren</button>
    <h2>{{ $t('views.locations.devices.title') }}</h2>
    <p class="h5">{{ $t('views.locations.devices.description') }}</p>

    <b-table :items="assignedDevices.items" :fields="assignedDevices.fields"
      :busy="assignedDevices.isLoading"
      striped
      hover
      show-empty
      :sort-by.sync="assignedDevices.sortBy" :sort-desc.sync="assignedDevices.sortDesc" @sort-changed="onAssignedDevicesSortChanged">
      <template #cell(created)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(actions)="data">
        <b-button variant="danger" size="sm" @click="unassignDevice(data.item.id)"><b-icon icon="trash" /> {{ $t('views.locations.devices.buttons.removeassignement') }}</b-button>
      </template>
    </b-table>
    <h2>{{ $t('views.locations.devices.unassigned.title') }}</h2>
    <p class="h5">{{ $t('views.locations.devices.unassigned.description') }}</p>
    <b-table :items="unassignedDevices.items" :fields="unassignedDevices.fields"
      :busy="unassignedDevices.isLoading"
      striped
      hover
      show-empty
      :sort-by.sync="unassignedDevices.sortBy" :sort-desc.sync="unassignedDevices.sortDesc" @sort-changed="onUnassignedDevicesSortChanged">
      <template #cell(created)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(actions)="data">
        <b-button variant="primary" size="sm" @click="assignDevice(data.item.id)"><b-icon icon="plus" /> {{ $t('views.locations.devices.buttons.addassignement') }}</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http/core'
import { STOREMODULE_ENDPOINTMODULE_MAP, STOREMODULE_ENDPOINT_MAP } from '@/constants'

export default {
  name: 'Locations.UserVisits',
  mixins: [MixinEntityBase],
  data () {
    return {
      assignedDevices: {
        items: [],
        fields: [
          { key: 'created', label: 'Datum', sortable: true, class: 'w-25' },
          { key: 'serialNumber', label: 'Gerät-Id', sortable: true, class: 'w-50' },
          { key: 'doorUnlockCount', label: 'Anzahl Türöffnungen', sortable: true },
          { key: 'actions', label: 'Aktionen', class: 'text-right' }
        ],
        sortBy: 'created',
        sortDesc: true,
        isLoading: true
      },
      unassignedDevices: {
        items: [],
        fields: [
          { key: 'created', label: 'Datum', sortable: true, class: 'w-25' },
          { key: 'serialNumber', label: 'Gerät-Id', sortable: true, class: 'w-50' },
          { key: 'actions', label: 'Aktionen', class: 'text-right' }
        ],
        sortBy: 'created',
        sortDesc: true,
        isLoading: true
      }
    }
  },
  mounted () {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      this.assignedDevices.isLoading = true
      this.unassignedDevices.isLoading = true

      http({
        method: 'get',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.devices}/fromlocation/${this.eParent.id}`
      }).then(response => {
        if (response.data.success === true) {
          this.assignedDevices.items = response.data.result
        }
      }).finally(() => {
        this.assignedDevices.isLoading = false
      })

      http({
        method: 'get',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.devices}/unassigned`
      }).then(response => {
        if (response.data.success === true) {
          this.unassignedDevices.items = response.data.result
        }
      }).finally(() => {
        this.unassignedDevices.isLoading = false
      })
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleString()
    },
    onAssignedDevicesSortChanged (sortInfo) {
      this.assignedDevices.sortBy = sortInfo.field
      this.assignedDevices.sortDesc = sortInfo.order === 'desc'
    },
    onUnassignedDevicesSortChanged (sortInfo) {
      this.unassignedDevices.sortBy = sortInfo.field
      this.unassignedDevices.sortDesc = sortInfo.order === 'desc'
    },
    unassignDevice (deviceId) {
      this.assignedDevices.isLoading = true
      this.unassignedDevices.isLoading = true

      http({
        method: 'put',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.devices}/unassign/${deviceId}`
      }).then(response => {
        if (response.data.success === true) {
          this.fetchItems()
        }
      })
    },
    assignDevice (deviceId) {
      this.assignedDevices.isLoading = true
      this.unassignedDevices.isLoading = true

      http({
        method: 'put',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.devices}/assign/${deviceId}/to/${this.eParent.id}`
      }).then(response => {
        if (response.data.success === true) {
          this.fetchItems()
        }
      })
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getUnwrapped`]
    }
  }
}
</script>

<style lang="scss"></style>
